import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainPage } from "./pages/MainPage/MainPage";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "./config";
import { Header } from "./components/Header/Header";
import EventFormPage from "./pages/EventFormPage/EventFormPage";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { EventsPage } from "./pages/EventsPage/EventsPage";
import { EventDetailsPage } from "./pages/EventDetailsPage/EventDetailsPage";

function App() {
  const [events, setEvents] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [timing, setTiming] = useState("newEvents");

  useEffect(() => {
    const getAuthenticated = () => {
      setAuthenticated(JSON.parse(sessionStorage.getItem("jwt")));
    };
    if (sessionStorage.getItem("jwt")) {
      getAuthenticated();
    }
  }, []);

  useEffect(() => {
    const getUser = () => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${authenticated}`,
      };
      axios
        .get(`${url}/users`)
        .then((response) => {
          setUser(response.data);
          if (response.data.role === "Planner") {
            return axios.get(`${url}/events`);
          }
          if (response.data.role === "Vendor") {
            return axios.get(
              `${url}/vendors/${response.data.vendor.id}/events`
            );
          }
        })
        .then((response) => {
          let sortedArray = sortArray(response.data, timing);
          setEvents(sortedArray);
        });
    };
    if (authenticated) {
      getUser();
    }
  }, [authenticated, timing]);

  const handleUpdate = (type) => {
    if (type === "events") {
      axios.get(`${url}/events`).then((response) => {
        let sortedArray = sortArray(response.data, "newEvents");
        setEvents(sortedArray);
      });
    } else if (type === "vendor") {
      axios.get(`${url}/users`).then((response) => {
        setUser(response.data);
      });
    } else if (type === "vendorEvents") {
      axios.get(`${url}/vendors/${user.vendor.id}/events`).then((response) => {
        let sortedArray = sortArray(response.data, "newEvents");
        setEvents(sortedArray);
      });
    }
  };

  const sortArray = (array, timing) => {
    let today = new Date();

    if (timing === "newEvents") {
      array = array.filter((item) => new Date(item.eventDate) > today);
    }

    if (timing === "oldEvents") {
      array = array.filter((item) => new Date(item.eventDate) < today);
    }

    return array.sort((eventA, eventB) => {
      return new Date(eventA.eventDate) - new Date(eventB.eventDate);
    });
  };

  const handleLogin = (data) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("jwt", JSON.stringify(data.token));
      setAuthenticated(JSON.parse(sessionStorage.getItem("jwt")));
    }
  };

  const handleTiming = (value) => {
    setTiming(value);
  };

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem("jwt");
    }
    setAuthenticated(false);
  };

  return (
    <div className="App">
      <BrowserRouter>
        {authenticated && <Header handleLogout={handleLogout} user={user} />}
        <Routes>
          <Route
            path="login"
            element={
              <MainPage
                handleLogin={handleLogin}
                authenticated={authenticated}
                action="signin"
              />
            }
          />
          <Route
            path="signup"
            element={<MainPage handleLogin={handleLogout} action="signup" />}
          />
          <Route
            path="events/add-event"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  action="add"
                  elementType="event"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:eventId/edit-event"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  action="edit"
                  elementType="event"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:eventId/add-vendor"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  action="add"
                  elementType="vendor"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:eventId"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventDetailsPage user={user} authenticated={authenticated} />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendors/:vendorId/edit"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  elementType="vendor"
                  action="edit"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendor/:vendorId/event/:eventId"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  elementType="vendorEvent"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendor/:vendorId/event/:eventId/chat"
            element={
              <ProtectedRoute
                authenticated={authenticated}
                redirectPath="/login"
              >
                <EventFormPage
                  elementType="chat"
                  handleUpdate={handleUpdate}
                  authenticated={authenticated}
                  user={user}
                />
              </ProtectedRoute>
            }
          />
          {events && (
            <Route
              path=""
              element={
                <ProtectedRoute
                  authenticated={authenticated}
                  redirectPath="/login"
                >
                  <EventsPage
                    events={events}
                    user={user}
                    handleChange={handleTiming}
                  />
                </ProtectedRoute>
              }
            />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
