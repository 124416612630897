import "./ChatForm.scss";
import { MessagesList } from "../MessagesList/MessagesList";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../Icon/Icon";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { chatUrl } from "../../config";

const io = require("socket.io-client");
const socket = io("https://api.partyagile.com/");

export const ChatForm = ({ authenticated, user }) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState(null);
  const navigate = useNavigate();
  const { vendorId, eventId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    socket.emit("join chat", {
      room: `Chat-${vendorId}-${eventId}`,
    });
    socket.emit("messages", {
      values: { vendorId: vendorId, eventId: eventId },
      room: `Chat-${vendorId}-${eventId}`,
    });
    return () => {
      socket.emit("leave chat", {
        room: { vendorId: vendorId, eventId: eventId },
      });
    };
  }, [vendorId, eventId]);

  useEffect(() => {
    socket.on("messages", (payload) => {
      setMessages(payload);
      setLoading(false);
    });
    return () => {
      socket.off("messages");
    };
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    let message = {
      content: data.message,
      created: new Date(),
      eventId: eventId,
      sender: user.name,
      vendorId: vendorId,
    };
    socket.emit("new message", {
      message: message,
      room: `Chat-${vendorId}-${eventId}`,
    });
    reset({ message: "" });
  };

  return loading ? (
    <p>Loading ...</p>
  ) : (
    <main className="chatForm">
      <div className="chatForm__header">
        <h2>
          Conversations with{" "}
          {user && user.role === "Planner" ? "Vendor" : "Planner"}
        </h2>
        <div className="chatForm__header-actions">
          <button
            type="button"
            onClick={goBack}
            className="chatForm__header-actions__link"
          >
            <Icon name="close" />
            <p className="chatForm__header-actions__link-text">Close Chat</p>
          </button>
        </div>
      </div>
      <div className="chatForm__content">
        {messages && user && <MessagesList messages={messages} user={user} />}
      </div>
      <form className="chatForm__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="chatForm__form__row chatForm__form__row--text">
          <label className="chatForm__form__row-controls__label">
            Message:
          </label>
          <div className="chatForm__form__row-controls__input">
            <textarea
              className="chatForm__form__row-controls__input-text"
              {...register("message", { required: true })}
              autoComplete="off"
            />
            {errors.message && (
              <p className="chatForm__form__row-controls__input-error">
                You must insert a message!
              </p>
            )}
          </div>
          <button className="chatForm__form__row-controls__button">Send</button>
        </div>
      </form>
    </main>
  );
};
