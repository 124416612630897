import "./EventsPage.scss";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { EventsList } from "../../components/EventsList/EventsList";

export const EventsPage = ({ events, user, handleChange }) => {
  return (
    <div className="events">
      <PageHeader
        elementType={user && user.role === "Planner" ? "Events" : "Vendors"}
        buttonText={
          user && user.role === "Planner"
            ? "Create New Event"
            : "Edit Vendor Info"
        }
        user={user}
        handleChange={handleChange}
      />
      <div className="events-content">
        <EventsList events={events} user={user} />
      </div>
    </div>
  );
};
