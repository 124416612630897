import "./MessagesList.scss";
import { MessageItem } from "../MessageItem/MessageItem";
import { useEffect, useRef } from "react";

export const MessagesList = ({ messages, user }) => {
  const listEndRef = useRef(null);

  const scrollToEnd = () => {
    listEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  useEffect(scrollToEnd, [messages]);

  return (
    <ul className="message-list">
      <div className="message-list__container">
        <div ref={listEndRef} />
        {messages.map((message) => {
          return (
            <MessageItem
              key={message.id}
              owner={user && user.name === message.sender ? "yours" : ""}
              name={message.sender}
              content={message.content}
              created={message.created}
            />
          );
        })}
      </div>
    </ul>
  );
};
