import "./EventFormPage.scss";
import { EventForm } from "../../components/EventForm/EventForm";
import { VendorForm } from "../../components/VendorForm/VendorForm";
import { PaymentForm } from "../../components/PaymentForm/PaymentForm";
import { ChatForm } from "../../components/ChatForm/ChatForm";

const EventFormPage = ({
  elementType,
  action,
  handleUpdate,
  authenticated,
  user,
}) => {
  return (
    <section className="form-section">
      {elementType === "event" && (
        <EventForm
          action={action === "add" ? "Add New" : "Edit"}
          handleUpdate={handleUpdate}
          authenticated={authenticated}
        />
      )}
      {elementType === "vendor" && (
        <VendorForm
          action={action === "add" ? "Add" : "Edit"}
          authenticated={authenticated}
          handleUpdate={handleUpdate}
        />
      )}
      {elementType === "vendorEvent" && (
        <PaymentForm
          authenticated={authenticated}
          handleUpdate={handleUpdate}
        />
      )}
      {elementType === "chat" && (
        <ChatForm authenticated={authenticated} user={user} />
      )}
    </section>
  );
};

export default EventFormPage;
